.App {
  font-family: "Poppins", sans-serif;
  background: #fafafa;
  font-weight: 300;
}
p {
  font-size: 1.1em;
  line-height: 1.7em;
  color: #999;
}
a,
a:hover,
a:focus {
  color: inherit;
  text-decoration: none;
  transition: all 0.3s;
}
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400&display=swap");
.font-montserrat {
  font-family: "Montserrat", sans-serif;
}
.navbar {
  padding: 15px 10px;
  background: #fff;
  border: none;
  border-radius: 0;
  margin-bottom: 40px;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1);
}

.navbar-btn {
  box-shadow: none;
  outline: none !important;
  border: none;
}

.line {
  width: 100%;
  height: 1px;
  border-bottom: 1px dashed #ddd;
  margin: 40px 0;
}

i {
  font-size: 25px;
}
i,
span {
  display: inline-block;
}

.btn.focus,
.btn:focus {
  outline: 0;
  box-shadow: none;
}

.navFooter {
  position: fixed;
  bottom: 10px;
  width: 250px;
}
/* ---------------------------------------------------
    SIDEBAR STYLE
----------------------------------------------------- */

.wrapper {
  display: flex;
  align-items: stretch;
}

#sidebar {
  min-width: 250px;
  max-width: 250px;
  background: #411b22;
  transition: all 0.3s;
  color: white;
}

#sidebar.active {
  min-width: 80px;
  max-width: 80px;
  text-align: center;
}

#sidebar.active .sidebar-header h3,
#sidebar.active .CTAs,
#sidebar.active .navFooter {
  display: none;
}

#sidebar.active .sidebar-header strong {
  display: block;
}

#sidebar ul li a {
  text-align: left;
}

#sidebar.active ul li a {
  display: none;
  padding: 20px 10px;
  text-align: center;
  font-size: 0.85em;
  letter-spacing: 0.75px;
}

#sidebar ul .link a {
  text-align: left;
}

#sidebar.active ul .link a {
  display: none;
  padding: 20px 10px;
  text-align: center;
  font-size: 0.85em;
  letter-spacing: 0.75px;
}

#sidebar .sidebar-header {
  padding: 10px;
}

#sidebar .sidebar-header strong {
  display: none;
  font-size: 1.8em;
}

#sidebar ul.components {
  position: fixed;
  top: 50%;
  transform: translateY(-50%);
  padding: 20px 10px;
}

#sidebar ul li a {
  padding: 10px;
  font-size: 0.95em;
  display: block;
}

/* #sidebar ul li a:hover {
  color: #7386d5;
  background: #fff;
} */

#sidebar ul li a i {
  margin-right: 10px;
}

#sidebar ul li.active > a,
a[aria-expanded="true"] {
  color: #fff;
  background: #6d7fcc;
}

a[data-toggle="collapse"] {
  position: relative;
}

#sidebar ul .link a {
  padding: 10px;
  font-size: 0.95em;
  display: block;
}

/* #sidebar ul li a:hover {
    color: #7386d5;
    background: #fff;
} */

#sidebar ul .link a i {
  margin-right: 10px;
}

#sidebar ul .link.active > a,
a[aria-expanded="true"] {
  color: #fff;
  background: #6d7fcc;
}

a[data-toggle="collapse"] {
  position: relative;
}

.dropdown-toggle::after {
  display: block;
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
}

ul ul a {
  font-size: 0.9em !important;
  padding-left: 30px !important;
  background: #6d7fcc;
}

ul.CTAs {
  padding: 20px;
}

ul.CTAs a {
  text-align: center;
  font-size: 0.9em !important;
  display: block;
  border-radius: 5px;
  margin-bottom: 5px;
}

a.download {
  background: #fff;
  color: #7386d5;
}

a.article,
a.article:hover {
  background: #6d7fcc !important;
  color: #fff !important;
}

/* ---------------------------------------------------
    CONTENT STYLE
----------------------------------------------------- */

#content {
  width: 100%;
  /* padding: 20px; */
  margin-left: 80px;
  /* min-height: 100vh; */
  transition: all 0.3s;
}

/*-------------------------------------------------------
                            Screen 3 
 -------------------------------------------------------*/
.bg-holder {
  height: 55vh;
  background-color: #e5ded9;
}
.bg-holder-school {
  height: 50vh;
  width: 70%;
  /* background-color: ; */
  background-image: url("https://res.cloudinary.com/dtfecnt6o/image/upload/v1627129532/swastika%20school%20of%20dance%20schedule/swastika-school-of-dance_giqla4_werwnq.webp");
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}
@media (max-width: 600px) {
  .bg-holder-school {
    width: 100%;
    background-size: cover;
  }
}
.bg-holder-about {
  height: 55vh;
  /* background-image: url("./Images/AboutUSBackground.JPG"); */
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.aboutUsCarouselImage {
  height: 530px;
}
.aboutUsCarouselImage img {
  height: 700px;
  width: 80%;
}
@media (max-width: 767px) {
  .aboutUsCarouselImage {
    height: 250px;
  }
  .aboutUsCarouselImage img {
    height: 250px;
    width: 100%;
  }
}
.swiper-button-next::after,
.swiper-button-prev::after {
  color: #ffffff00;
  min-height: 100px;
  min-width: 30px;
}
.swiper-button-next::after {
  background-image: url("data:image/svg+xml,%3Csvg width='100' height='100' xmlns='http://www.w3.org/2000/svg' xml:space='preserve' enable-background='new 0 0 100 100' version='1.1'%3E%3Cg%3E%3Ctitle%3ELayer 1%3C/title%3E%3Cpath fill='%23d7cec7' id='svg_1' d='m24.4,46.8c-1.8,1.8 -1.8,4.7 0,6.4l40.3,40.3c2,2 5.2,2 7.2,0c2,-2 2,-5.2 0,-7.2l-33.1,-33.1c-1.8,-1.8 -1.8,-4.7 0,-6.4l33.1,-33.1c2,-2 2,-5.2 0,-7.2c-2,-2 -5.2,-2 -7.2,0l-40.3,40.3z'/%3E%3C/g%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  transform: rotate(180deg);
}
.swiper-button-prev::after {
  background-image: url("data:image/svg+xml,%3Csvg width='100' height='100' xmlns='http://www.w3.org/2000/svg' xml:space='preserve' enable-background='new 0 0 100 100' version='1.1'%3E%3Cg%3E%3Ctitle%3ELayer 1%3C/title%3E%3Cpath fill='%23d7cec7' id='svg_1' d='m24.4,46.8c-1.8,1.8 -1.8,4.7 0,6.4l40.3,40.3c2,2 5.2,2 7.2,0c2,-2 2,-5.2 0,-7.2l-33.1,-33.1c-1.8,-1.8 -1.8,-4.7 0,-6.4l33.1,-33.1c2,-2 2,-5.2 0,-7.2c-2,-2 -5.2,-2 -7.2,0l-40.3,40.3z'/%3E%3C/g%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
@media (min-width: 768px) {
  .swiper-button-next::after,
  .swiper-button-prev::after {
    min-height: 40px;
    min-width: 30px;
  }
  .swiper-button-next::after {
    margin-right: 900%;
  }
  .swiper-button-prev::after {
    margin-left: 900%;
  }
}
.customp {
  font-weight: 400;
  font-size: 1em;
  padding: 50px 17%;
  line-height: 150%;
}
.div-bg-pic {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.squarepic1 {
  background-image: url("https://res.cloudinary.com/dtfecnt6o/image/upload/v1627129622/Anuradha_nwm3fa_wx4lxb.webp");
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  min-height: 60vh;
  width: 100%;
  /* background-color: white; */
  z-index: 99;
}
.squarepic2 {
  background-image: url("https://res.cloudinary.com/dtfecnt6o/image/upload/v1627129622/Natarajan_vcun0e_wchkhn.webp");
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  min-height: 60vh;
  width: 100%;
  /* background-color: white; */
  z-index: 99;
}

.nattext {
  padding-left: 3rem;
}

/*-------------------------------------------------------
                            Screen 4 
 -------------------------------------------------------*/
.holderbg1 {
  background-image: url("https://res.cloudinary.com/dtfecnt6o/image/upload/v1627129423/events/kalakaribgpic3_t7s8oq-min_kyyf6k.webp");
  background-position: top;
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 65vh;
  /* background-color: #e4e4e4; */
  width: 100%;
}
.holderbg3 {
  background-image: url("https://res.cloudinary.com/dtfecnt6o/image/upload/v1627129423/events/musicbgpic2_x96ri3_ufynzt.webp");
  background-position: top;
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 65vh;
  /* background-color: #e4e4e4; */
  width: 100%;
}
.holderbg2 {
  background-image: url("https://res.cloudinary.com/dtfecnt6o/image/upload/v1627129423/events/sssbgpic1_imroc8_ialsg1.webp");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 65vh;
  /* background-color: #e4e4e4; */
  width: 100%;
}
.holderbg4 {
  background-image: url("https://res.cloudinary.com/dtfecnt6o/image/upload/v1628081968/events/y4mvXln-HWwNlup86Y8sq3qsjRiNDSAIYkpTJLpTia9_5TD9io8-J4z7SFXupgPzDjSZGH-BMjcGp9nIMRkik2iidSiU6GZmcA3mUmtiKX2Szq4T2Iu56fMTS3rJvWFS2_Mmc30Wc0sBfO9Pk6YUpBPeJaWceL07br19WlzWBajd3Sv3QEi5eI-po4uMBWimk6f_tvdzvz.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 65vh;
  /* background-color: #e4e4e4; */
  width: 100%;
}

.customp1 {
  font-weight: 400;
  font-size: 1.1em;
  padding: 30px 7.5%;
  color: #41363d;
  line-height: 150%;
}

/*-------------------------------------------------------
                            Screen 6
 -------------------------------------------------------*/

.bg-holderbg1 {
  height: 47vh;
  width: 60%;

  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.bg-holderbg2 {
  height: 47vh;
  width: 60%;

  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.bg-holderbg3 {
  height: 47vh;
  width: 60%;

  background-position: center;
  background-size: cover;

  background-repeat: no-repeat;
}

.customP3 {
  padding: 0 10px;
  border-right: 2px solid black;
}
.customP3not {
  padding: 0 10px;
}

/*-------------------------------------------------------
                            Screen 7
 -------------------------------------------------------*/
.bg-image-cover {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.squarebgpic2 {
  /* background-image: url("./Images/classpic1.JPG"); */
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 36vh;
  /* width: 100px; */
  /* max-width: 80%; */
  /* z-index: 99; */
}
.squarebgpic3 {
  /* background-image: url("./Images/carnoic2.jpg"); */
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 36vh;

  width: 80%;

  z-index: 99;
  float: none;
}

.customp7 {
  /* font-weight: 500; */
  font-size: 0.95em;
  color: #41363d;
  font-weight: 400;
  line-height: 150%;
  text-align: justify;
}
#block1 {
  /* border-left: 1px solid #411b22; */
  line-height: 60%;
}
/* ---------------------------------------------------
    MEDIAQUERIES
----------------------------------------------------- */

@media (max-width: 768px) {
  #sidebar {
    min-width: 250px;
    max-width: 250px;
    background: #411b22;
    transition: all 0.3s;
    color: white;
    height: 100vh;
  }

  #sidebar.active {
    min-width: 0px;
    max-width: 0px;
    text-align: center;
  }
  #sidebar.active .btn-main {
    display: none;
  }
  .dropdown-toggle::after {
    top: auto;
    bottom: 10px;
    right: 50%;
    -webkit-transform: translateX(50%);
    -ms-transform: translateX(50%);
    transform: translateX(50%);
  }
  #sidebar.active {
    margin-left: 0 !important;
  }
  #sidebar .sidebar-header h3,
  #sidebar .CTAs {
    display: none;
  }
  #sidebar .sidebar-header strong {
    display: block;
  }
  #sidebar ul li a {
    padding: 20px 10px;
  }
  #sidebar ul li a span {
    font-size: 0.85em;
  }
  #sidebar ul li a i {
    margin-right: 0;
    display: block;
  }
  #sidebar ul ul a {
    padding: 10px !important;
  }
  #sidebar ul li a i {
    font-size: 1.3em;
  }
  #sidebar {
    margin-left: 0;
  }
  #sidebarCollapse span {
    display: none;
  }
  .square {
    height: 35vh;
    width: 30vh;
    background-color: white;
    z-index: 99;
    position: relative;
    right: 10%;
  }
  .squarepic1 {
    background-image: url("https://res.cloudinary.com/dtfecnt6o/image/upload/v1627129622/Anuradha_nwm3fa_wx4lxb.webp");
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    min-height: 50vh;
    width: 100%;
    /* background-color: white; */
    z-index: 99;
    float: none;
  }
  .squarepic2 {
    background-image: url("https://res.cloudinary.com/dtfecnt6o/image/upload/v1627129622/Natarajan_vcun0e_wchkhn.webp");
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    min-height: 50vh;
    width: 110%;
    /* background-color: white; */
    z-index: 99;
    float: none;
  }
  .customp {
    padding: 50px 12%;
  }

  .nattext {
    padding: unset;
    margin: 0 20px 0 20px;
  }

  .nospace {
    margin: 0 !important;
  }
  .squarebgpic3 {
    /* background-image: url("./Images/carnoic2.jpg"); */
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    min-height: 45vh;
  }

  /*-------------------------------------------------------
                            Screen 4 
 -------------------------------------------------------*/
  .bg-holder1 {
    height: 45vh;
    background-color: #e4e4e4;
    width: 100%;
  }

  .customp1 {
    font-weight: 400;
    font-size: 1.1em;
    padding: 30px 7.5%;
    color: #4d4249;
    line-height: 150%;
  }
  /*-------------------------------------------------------
                            Screen 6
 -------------------------------------------------------*/
  .bg-holder2 {
    height: 50vh;
    width: 100%;
    background-color: #e5ded9;
  }

  .customP3 {
    padding: 0 10px;
    /* border-right: 3px solid black; */
  }

  .square2 {
    height: 35vh;
    width: 30vh;
    /* background-color: white; */
    z-index: 99;
    float: none;
    margin: auto;
    /* position: relative;
        right: 10%; */
  }
  .square3 {
    height: 35vh;
    width: 30vh;
    /* background-color: #d7cec7; */
    z-index: 99;
    float: none;
    margin: auto;
    /* z-index: 99;
        position: relative;
        right: 10%; */
  }
  .customp7 {
    font-weight: 400;
    font-size: 0.95em;
    color: #41363d;
    line-height: 150%;
    text-align: justify;
  }
  #block1 {
    border: none;
  }
  #content {
    width: 100%;
    padding: 20px;
    margin-left: 0;
    /* min-height: 100vh; */
    transition: all 0.3s;
  }
}

/* Unlisted */

*:focus {
  outline: none;
}

.newsField::placeholder {
  color: white;
}
.newsField {
  border-bottom: 2px solid white;
  max-width: 250px;
  margin-bottom: 0.25rem;
}

.inputCss {
  border: 1px solid lightgray;
}

.newsBtn {
  background-color: gray;
  height: 30px;
}

.newsBtn:focus {
  border: none;
  outline: none;
}

.dropOptions:hover {
  cursor: pointer;
  background-color: #bdb5af;
}
.dropOptions {
  padding: 0 3px;
}
.dropOpC {
  max-height: 150px;
  overflow-y: scroll;
}

.archiveImages {
  /* width: 350px;
  height: 250px; */
  min-width: 350px;
  min-height: 250px !important;
  max-height: 250px !important;
  max-width: 350px !important;
}
.archive-dot {
  margin-left: -8.5px;
}
@media (max-width: 640px) {
  .archive-dot {
    margin-left: -30px;
  }
  .archiveImages {
    margin-left: 2.2rem !important;
    min-width: 200px !important;
    max-width: 200px !important;
    min-height: 155px !important;
    max-height: 155px !important;
  }
}
@media (max-width: 400px) {
  .archiveImages {
    margin-left: 0.5rem !important;
  }
}
#topLogo img {
  transition: all 0.5s;
}

/* PRELOADER CSS */
.page-loader {
  position: absolute;
  z-index: 1000;
}

/* SPINNER ANIMATION */
.spinner {
  position: relative;
  top: 35%;
  width: 80px;
  height: 80px;
  margin: 0 auto;

  border-radius: 100%;
  -webkit-animation: sk-scaleout 1s infinite ease-in-out;
  animation: sk-scaleout 1s infinite ease-in-out;
}

@-webkit-keyframes sk-scaleout {
  0% {
    -webkit-transform: scale(0);
  }
  100% {
    -webkit-transform: scale(1);
    opacity: 0;
  }
}

@keyframes sk-scaleout {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 0;
  }
}

.rec.rec-arrow {
  border: none;
  box-shadow: none;
  background-color: transparent;
}
.rec.rec-arrow:enabled {
  background-color: transparent !important;
  box-shadow: none !important;
  border: none;
}
.rec.rec-arrow:focus {
  outline: none;
  border: none;
}
.rec.rec-arrow:disabled {
  visibility: hidden;
}
.rec.rec-dot:hover,
.rec.rec-dot:focus {
  box-shadow: 0 0 1px 3px #b96c34 !important;
}
.rec-dot_active:hover,
.rec-dot_active:focus,
.ejXxNI {
  box-shadow: 0 0 1px 3px #b96c34 !important;
  background-color: #b96c34 !important;
}

::-webkit-scrollbar {
  -webkit-appearance: none;
}

::-webkit-scrollbar:vertical {
  width: 12px;
}

::-webkit-scrollbar:horizontal {
  height: 12px;
}

::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  border: 2px solid #ffffff;
}

::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: #ffffff;
}

.bookText {
  margin-left: 15%;
  margin-right: 15%;
}
@media (max-width: 767px) {
  .bookText {
    margin-left: 0;
    margin-right: 0;
  }
}

.bg-holder-book {
  height: 50vh;
  width: 70%;
  /* background-color: ; */
  background-image: url("https://res.cloudinary.com/ahum/image/upload/v1624528295/Ahum/imageedit_18_7664862360_mfyrij.jpg");
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}
@media (max-width: 600px) {
  .bg-holder-book {
    width: 100%;
    background-size: cover;
  }
}
/* rsvp form */
#rsvp-form input {
  border: 1px solid;
}

/* toastify */
.Toastify__toast {
  margin-left: 80px;
}
@media (max-width: 600px) {
  .Toastify__toast {
    margin-left: 0;
  }
}
.Toastify__toast-container {
  width: fit-content;
}
.upcoming-shows-toast .Toastify__toast {
  margin-bottom: 27%;
}
.rsvp-form-toast .Toastify__toast {
  /* margin-bottom: 27%; */
}
.contactToast {
  margin-bottom: 7.5%;
}
@media (max-width: 600px) {
  .rsvp-form-toast .Toastify__toast,
  .upcoming-shows-toast .Toastify__toast {
    margin-bottom: 0;
  }
}
/* kalakrida thumbnail */
.kalakrida-thumbnail {
  width: 47vh;
  height: 31vh;
}
@media (max-width: 600px) {
  .kalakrida-thumbnail {
    width: 270px;
    height: 200px;
  }
}
